@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  padding: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;
  overflow: hidden;

  @include vp-435 {
    padding: 0;
  }
}

.highlight {
  color: $primary;
}
