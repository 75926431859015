@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  padding: 12px;
  background-color: $light-gray;
  border-radius: 8px;
  height: 86px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.tag {
  @include text(10px, 500, 20px);
  color: $gray;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title {
  @include text(14px, 700, 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text {
  @include text(12px, 400, 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
}

.highlight {
  color: $primary;
}
