@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: fit-content;
  padding: 8px;
  border-radius: 24px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  max-width: 40px;
  transition: all 0.5s;
  position: relative;

  cursor: pointer;
  box-sizing: border-box;
  max-height: 36px;
  background-color: $light-gray;
  z-index: 5;

  &:hover {
    background-color: $primary;

    .icon {
      path {
        stroke: $white;
      }
    }
  }

  &Active {
    width: 100%;
    max-width: 1200px;
    padding: 9px 12px 9px 12px;
    outline: 1px solid $primary;
    border-radius: 10px;
    gap: 10px;
    cursor: default;
    max-height: 40px;

    .icon {
      path {
        stroke: $gray;
      }
    }

    &:hover {
      background-color: $light-gray;

      .icon {
        path {
          stroke: $gray;
        }
      }
    }

    .clear {
      max-height: 24px;
    }
  }
}

.search {
  width: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  height: 100%;
  outline: none;
  color: $black;
  @include text-16;
}

.active {
  width: 100%;
}

.icon {
  path {
    stroke: $black;
  }
  transition: all 0.3s;
}

.clear {
  position: absolute;
  right: 12px;
  cursor: pointer;
  transition: all 0.3s;
  max-height: 0px;
}

.results {
  position: absolute;
  top: 100%;
  width: 99%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
  border-radius: 0px 0px 24px 24px;
  min-height: 140px;
  padding: 24px;
  z-index: -1;
  animation: show 0.3s;

  @keyframes show {
    0% {
      opacity: 0;
    }
    99% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @include vp-1439 {
    width: 100%;
    left: 0;
  }
}

button.resultsButton {
  padding: 0;
}
